import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { AppConfigService } from '../app.config';
import { CustomEncoder } from '../shared/classes/customencoder';
import { LOADIPHLPAPI } from 'dns';
@Injectable({
  providedIn: 'root',
})
export class ReteService {
  public functionVar:any;
  public baseUrl = this.getUMPUrl();
  private UMP_URL =
    `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/execute`;
  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}
  getUMPUrl() {
    if (!location.hostname.includes('digitalform.io')) {
      return this.appConfigService.apiBaseUrl;
    } else {
      return location.protocol + '//' + location.hostname +
      ':' + (location.port || '');
    }
  }
  setHeaders() {
    let headers_object = new HttpHeaders();
    /** set request headers for http request */
    headers_object = headers_object.append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    headers_object = headers_object.append('accept', 'application/json');

    const headers = {
      headers: headers_object,
    };
    return headers;
  }
  setHeadersWithApi() {
    let headers_object = new HttpHeaders();
    /** set request headers for http request */
    headers_object = headers_object.append(
      'Content-Type',
      'text/plain',
    );
    headers_object = headers_object.append('accept', '*/*');
    headers_object=headers_object.append('responseType','text')
    headers_object = headers_object.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const headers = {
      headers: headers_object

    };
    return headers;
  }
  setBody(inputMessage: any) {
    const inputparamsjson = JSON.stringify(inputMessage);
    const encodedInput = new CustomEncoder().encodeValue(inputparamsjson);
    const body = `queuedExecute=false&messageFormat=custom&inputMessage=${encodedInput}`;
    return body;
  }

  setWorkflowMessageBody(formdata: any, eventdata: any, custominput: any, formId: any, sessionId: any, wfType: any, wfNamespace: any, taskId: string, formUsers:any) {
    const formDataJson = JSON.stringify(formdata);
    // const eventDatajson = JSON.stringify(eventdata);
    // const custominputjson = JSON.stringify(custominput);
    const encodedFormData = new CustomEncoder().encodeValue(formDataJson);
    const encodedEventData = new CustomEncoder().encodeValue(eventdata);
    const encodedCustomInputData = new CustomEncoder().encodeValue(custominput);

    const formUsersJson = JSON.stringify(formUsers);
    const encodedformUsers = new CustomEncoder().encodeValue(formUsersJson);
    const body = `queuedExecute=false&messageFormat=custom&formdata=${encodedFormData}&eventdata=${encodedEventData}&inputMessage=${encodedCustomInputData}&formId=${formId}&sessionId=${sessionId}&wfType=${wfType}&wfNamespace=${wfNamespace}&taskId=${taskId}&formusers=${encodedformUsers}`;
    return body;
  }

  importformfields(formid) {
    const inputparams = {
      formId: formid
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_FIELDS`,
      body,
      headers
    );
  }
  getPAlibrariesAndFunctions(inputparams: any) {
    // const inputparams = {};
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_ALL_LIBRARY_FUNCTIONS`,
      body,
      headers
    );
  }
  createworkflow(workflowform: any, icon: string) {
    const inputparams = {
      ...workflowform,
      avatar: icon,
      wfType: 'flow',
      draft: true,
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_WORKFLOW_DATA`,
      body,
      headers
    );
  }
  copyworkflow(workflowform: any, icon: string, wfId: string) {
    const inputparams = {
      ...workflowform,
      avatar: icon,
      wfType: 'flow',
      wfId: wfId,
      draft: true,
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_COPY_WORKFLOW`,
      body,
      headers
    );
  }
  updateworkflow(workflowform: any, icon: string, wfId: string) {
    const inputparams = {
      ...workflowform,
      avatar: icon,
      wfType: 'flow',
      wfId: wfId,
      draft: true,
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_UPDATE_WORKFLOW_DETAILS`,
      body,
      headers
    );
  }
  saveworkflowoperation(wfOperationform: any, language: string, icon: string, wfOperationId: string, isUpdate: boolean, data: string) {
     const inputparams = {
      id: wfOperationId,
      title: wfOperationform.opTitle,
      name: wfOperationform.opName,
      desc: wfOperationform.opDesc,
      inputs: wfOperationform.inputs,
      outputs: wfOperationform.outputs,
      results: wfOperationform.results,
      type: language,
      avatar: icon,
      data: data,
      update: isUpdate,
      javaClass: wfOperationform.javaClass,
      javaMethod: wfOperationform.javaMethod
      };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_WF_OPERATION`,
      body,
      headers
    );
  }
  createworkflowoperation(wfOperationform: any, icon: string) {
    const inputparams = {
     title: wfOperationform.opTitle,
     name: wfOperationform.opName,
     desc: wfOperationform.opDesc,
     type: wfOperationform.language,
     avatar: icon
     };
   const body = this.setBody(inputparams);
   const headers = this.setHeaders();
   return this.http.post<any>(
     `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_WF_OPERATION`,
     body,
     headers
   );
 }
  saveworkflowdata(workflowid: string, workflowdata: any, saveNodedata: boolean, formType?: string, formId?: string, oldActionOnDeploy?: string, actionOnDeploy?: string, wfName?: string) {
    const inputparams = {
      wfId: workflowid,
      data: workflowdata,
      saveNodedata: saveNodedata,
      formType: formType,
      draft: true,
      formId: formId,
      oldActionOnDeploy: oldActionOnDeploy,
      actionOnDeploy: actionOnDeploy,
      wfName: wfName
    };

    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_WORKFLOW_DATA`,
      body,
      headers
    );
  }
  getworkflow(workflowid: string, formType: string) {
    const inputparams = {
      wfId: workflowid,
      formType: formType
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WORKFLOW_DATA`,
      body,
      headers
    );
  }
  getworkflowsystems(syatemtype: string) {
    const inputparams = {
      sysType: syatemtype
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_SYSTEMS`,
      body,
      headers
    );
  }
  getOdataMetadata(url: string) {
    // const headers = this.setHeaders();
    return this.http.get(
      url,
      { responseType: 'text' }
    );
  }
  deleteflowsystem(syatemname: string) {
    const inputparams = {
      sysName: syatemname
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DELETE_WF_SYSTEM`,
      body,
      headers
    );
  }
  getworkflowsystemdetails(syatemtype: string, systemname: string) {
    const inputparams = {
      sysType: syatemtype,
      sysName: systemname
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_SYSTEM_DETAILS`,
      body,
      headers
    );
  }
  getworkflowsystemproperties(syatemtype: string, excludeparams: string) {
    const inputparams = {
      sysType: syatemtype,
      excludeParams: excludeparams
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_DEFAULT_WF_SYSTEM_PROPS`,
      body,
      headers
    );
  }
  createworkflowsystem(syatemdetailsform: any, systemtype: string, isUpdate: boolean) {
    const inputparams = {
      sysName: syatemdetailsform.name,
      sysType: systemtype,
      sysDesc: syatemdetailsform.description,
      systemProps: syatemdetailsform.properties,
      update: isUpdate
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_CREATE_WF_SYSTEM`,
      body,
      headers
    );
  }
  getallworkflowcategories() {
    const inputparams = {};
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_FORM_CATEGORY`,
      body,
      headers
    );
  }
  getallworkflows(excludeWfId: string, formCategory?: string) {
    const inputparams = {
      excludeWfId: excludeWfId,
      formCategory: formCategory
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_ALL_WORKFLOWS`,
      body,
      headers
    );
  }
  getinputformfields() {
    const inputparams = {};
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WORKFLOW_SUGGESTED_DATA`,
      body,
      headers
    );
  }
  getallformsforworkflow(type: string) {
    const inputparams = {
      type: type,
      sort_on:"formTitle",
      options: {
      "showPublished": true,
      "forWorkflow":true
    }
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORMS`,
      body,
      headers
    );
  }
  getallwfoperations() {
    const inputparams = {};
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_OPERATIONS`,
      body,
      headers
    );
  }
  getwfoperationdetails(wfOperationid: string) {
    const inputparams = {
      id: wfOperationid
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_OPERATION`,
      body,
      headers
    );
  }
  archivewfoperation(wfOperationid: string) {
    const inputparams = {
      id: wfOperationid
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DELETE_WF_OPERATION`,
      body,
      headers
    );
  }
  archiveworkflow(workflowid: string) {
    const inputparams = {
      wfId: workflowid
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DELETE_WORKFLOW`,
      body,
      headers
    );
  }

  getformsfielsfor$values(wfname: string, wfnamespace: string, formid: string, excludeparams: string) {
    const inputparams = {
      wfName: wfname,
      wfNameSpace: wfnamespace,
      formId: formid,
      excludeParams: excludeparams
    };
    // console.log(inputparams);
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WORKFLOW_SUGGESTED_DATA`,
      body,
      headers
    );
  }
  getFormsFielsForExpressionBuilder(nodeName: string, formId: string) {
    const inputparams = {
      nodeName: nodeName,
      formId: formId
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_FIELDS_FOR_EXPRESSION_BUILDER`,
      body,
      headers
    );
  }
  getAttributesForExpressionBuilder(formId: string) {
    const inputparams = {
      formId: formId
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_ATTRIBUTES`,
      body,
      headers
    );
  }
  getAllCalculatFunctions() {
    const inputparams = {};
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_CALC_NODE_FUNCTIONS`,
      body,
      headers
    );
  }
  getWorkflowDeployOptions(workflowId: string, formId: string, formType: string) {
    // console.log(excludeparams);
    const inputparams = {
      wfId: workflowId,
      formId: formId,
      formType: formType
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_DEPLOY_STATUS`,
      body,
      headers
    );
  }
  getWfTestForm(formId: string, workflowId: string) {
    const inputparams = {
      formId: formId,
      wfId: workflowId
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_FORM_DATA_FOR_WORKFLOW_TEST`,
      body,
      headers
    );
  }
  generateScript(language: string, inputParams: string, code?: string) {
    const inputparams = {
      language: language,
      input: inputParams
    };
    if(code) {
      inputparams['script'] = btoa(code);
    }
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_WF_GENERATE_SCRIPT`,
      body,
      headers
    );
  }
  getOutputParamsForScriptNodes(code: string) {
    const inputparams = {
      input: code
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_WF_SEND_RESULT_FOR_JS_PY_NODES`,
      body,
      headers
    );
  }
  saveWfTestForm(formId: string, formData: any, eventData: any, wfId: string, custominput: any, formUserData:any) {
    const inputparams = {
      formId: formId,
      testData: formData,
      eventData: eventData,
      wfId: wfId,
      custominput: custominput,
      formUserData: formUserData
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_SAVE_WF_TEST_DATA`,
      body,
      headers
    );
  }
  getWfTestData(formId: string, workflowId: string) {
    const inputparams = {
      formId: formId,
      wfId: workflowId
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_GET_WF_TEST_DATA`,
      body,
      headers
    );
    
    
  }
  getRunWfEvents(wfname: string, wfNamespace: string, wfType: string, formdata: any, eventdata: any,formid: string, sessionId: string, custominput: any, taskId: string, formUsers:any) {
    // const inputparams = {
    //   // wfName:wfname,
    //   // wfNamespace:wfNamespace,
    //   // wfType:wfType,
    //   formdata: formdata,
    //   // formid: formid,
    //   // sessionId: sessionId,
    //   eventdata: eventdata
    // };
    // const body = this.setBody(inputparams);

    const body = this.setWorkflowMessageBody(formdata, eventdata, custominput, formid, sessionId, wfType, wfNamespace, taskId, formUsers);
    // const headers = this.setHeaders();

    const headers = this.setHeaders();

    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // headers = headers.append('accept', 'application/json');
    // let params = new HttpParams({ encoder: new CustomEncoder() });
    // params = params.append("wfNamespace", wfNamespace);
    // params = params.append("wfType", wfType);
    // params = params.append("formdata", JSON.stringify(formdata));
    // params = params.append("eventdata", JSON.stringify(eventdata));
    // params = params.append("formid", formid);
    // params = params.append("sessionId", sessionId);
    // const httpOptions = {
    //   headers:headers,
    //   // params: params,

    // };
    return this.http.post<any>(
      `${this.baseUrl}/UMP/API/v2/applications/DIGITAL_FORMS/workflow/${wfname}`,
      body,
      headers
      // httpOptions,
      // headers
    );
  }
  deployWorkflow(workflowId: string, formId: string, oldActionOnDeploy: string, actionOnDeploy: string) {
    // console.log(excludeparams);
    const inputparams = {
      wfId: workflowId,
      formId: formId,
      actionOnDeploy: actionOnDeploy,
      oldActionOnDeploy: oldActionOnDeploy
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_DEPLOY_WORKFLOW`,
      body,
      headers
    );
  }
  getNestedFormsForApproval(workflowId: string, formId: string) {
    // console.log(excludeparams);
    const inputparams = {
      wfId: workflowId,
      formId: formId
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_WF_GET_APPROVALS_NESTED_FORMS`,
      body,
      headers
    );
  }
  getApprovalGroups(workflowId: string, formId: string) {
    // console.log(excludeparams);
    const inputparams = {
      wfId: workflowId,
      formId: formId
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_WF_GET_APPROVALS_GROUP`,
      body,
      headers
    );
  }
  downloadWf(conversationId:string){
     const headers =this.setHeadersWithApi();
    return this.http.get<any>(`${this.baseUrl}/UMP/API/v2/messages/conversation/${conversationId}/log`,headers);
  }
  sapRfcExplorerData(rfcName,sysName,exactSearch ){
    const inputparams = {
      RFCName:rfcName ,
      SAPSystemName: sysName,
      exactSearch:exactSearch
    };

    
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_WF_GET_SAP_EXPLORER_DATA`,
      body,
      headers
    );
  }
  updateMasterDataRecord(){
    const inputparams = {
      // formId:id
    };
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_WF_GET_MASTERDATA_LIST`,
      body,
      headers
    );
  }

  sapRfcInputOutput(rfcName,sysName){
    const inputparams={
        "RFCName":rfcName,
        "SAPSystemName":sysName
    }
    const body = this.setBody(inputparams);
    const headers = this.setHeaders();
    return this.http.post<any>(
      `${this.UMP_URL}/DIGITAL_FORMS_PA_WF_GET_SAP_RFC_INPUT_OUTPUT`,
      body,
      headers
    );
  }
 
  sapfunctionVariable(value:any){
     this.functionVar=value;
  }
  getsapfunctionVariable(){
    const val=this.functionVar;
    return val;
  }
}
